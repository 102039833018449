.customSelectWrapper {
    margin-left: 0 !important;
    &.noBorder {
        fieldset {
            border-color: transparent !important;
            box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f !important;
        }
    }
    &.error-border {
        fieldset {
            border-color: rgb(244, 67, 54) !important;
        }
    }
    .MuiInputLabel-animated {
        top: -3px;
        line-height: 1;
        background: white;
        color: rgba(0, 0, 0, 0.6) !important;
    }
    .MuiInputLabel-shrink {
        top: 0;
        margin: 0;
        display: block;
        line-height: 1.5;
        font-weight: bold;
    }
    .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #e9e9e9;
        }
        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid #e9e9e9;
            }
        }
    }
    fieldset {
        border-color: #000 !important;
        box-shadow: none;
    }
    fieldset {
        top: 0;
    }
    legend {
        display: none;
    }

}
