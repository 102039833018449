.dashboardTableSpace {
  margin: 2rem auto 0 !important;
  width: 95% !important;
}
.table-border {
  border-radius: 0 !important;
  border: 1px solid;
  th, td {
    border-bottom: 1px solid !important;
  }
}
.remove-search-boarder {
  input {
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
}
.MuiDataGrid-footerContainer {
  p {
    margin-bottom: 0;
  }
}
.overlay-table {
  background: #a1a1a161 !important;
}
.paginationWrapper {
  margin: 15px 0;
  ul {
    justify-content: center;
  }
}