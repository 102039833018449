.customInputWrapper {
  border-radius: 4px;
  position: relative;
  background: #fcfcfb;
  border: 1px solid transparent;
  padding: 6px 12px;
  margin-top: 8px;
  width: 100%;
  font-size: 1rem;

  &.darkMode {
    background: #2b2b2b;
  }
  &.widthBorder {
    border: 1px solid #000;
  }
  &:focus {
    box-shadow: 0 0 0 1px black;
  }
  &.input-success {
    border: 1px solid green !important;
  }
  &.input-success:focus {
    box-shadow: 0 0 0 1px green;
  }
}