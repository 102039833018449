.top-title-wrapper {
  padding: 8px 20px;
}
.form-wrapper {
  width: '100%';
  border: 4px solid #efe527;
  min-width: 400px;
  border-radius: 4px;
  box-shadow: 0 0 63px #c3c3c3d6;
  padding: 10px;
}
textarea {
  border-radius: 4px;
  border-color: #000;
  padding: 5px 10px;
}
@media (max-width: 768px) {
  .form-wrapper {
    min-width: 100%;
  }
}