.ActionLogTableWrapper {
  width: 95% !important;
  margin: 1rem auto 0 !important;

  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-wrapper {
      width: 100%;
    }
  }

  .button-wrapper.my-2 {
    display: flex;
  }

  .actionLogTableSpace {
    margin-top: 2rem;

    .table-icon-wrapper {
      // display: grid;
      // grid-template-columns: auto auto;
    }
  }
}

.submit-wrapper {
  width: 80%;
  margin: 0 auto;
}

.input-error-message {
  color: rgb(244, 67, 54);
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.input-error {
  border: 1px solid rgb(244, 67, 54) !important;
  box-shadow: 0 0 0 1px rgb(244, 67, 54) !important;
}