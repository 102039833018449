@font-face {
  font-family: "Avantt-bold";
  src: url("assets/Fonts/Avantt/Avantt-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-semiBold";
  src: url("assets/Fonts/Avantt/Avantt-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-light";
  src: url("assets/Fonts/Avantt/Avantt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-regular";
  src: url("assets/Fonts/Avantt/Avantt-Regular.ttf") format("truetype");
}

* {
  font-family: "Avantt-regular",Serif;
}

body,
html {
  font-family: "Avantt-regular",Serif, !important;
}

.custom-text-field input:-webkit-autofill,
.custom-text-field input:-webkit-autofill:hover,
.custom-text-field input:-webkit-autofill:focus,
.custom-text-field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}

.custom-text-field input::placeholder {
  color: #000 !important; /* Ensure placeholder text is visible */
}