.Links {
  margin-left: auto;
  display: flex;

  a {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;

    &:hover {
      color: white;
    }
  }

  p {
    margin-right: 2rem;

    span {
      font-size: 12px;
    }
  }
}
