.autocomplete-input {
  .MuiOutlinedInput-input {
    padding: 0 !important;
  }
  legend {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0 !important;
    border-color: #000 !important;
  }
  input:focus {
    box-shadow: none !important;
  }
  .MuiOutlinedInput-root {
    padding: 8px 15px !important;
  }
}