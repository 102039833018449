/********FOOTER*****************/
.footer {
    background-image: url("../Images/footer_wave.svg");
    background-size: cover;
    /* height: 100px; */
    background-color: #f8f8f8;
    background-size: 100% 60px;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 80px;
    padding: 2rem 1rem 80px;
    margin: auto;
  }
  .footer-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 90%;
    margin: auto;
    padding: 1rem;
    text-align: left;
  }
  .footer-bottom {
    font-size: 0.875rem;
    display: flex;
    justify-content: space-around;
  }
  .footer-items ul li {
    list-style: none;
  }
  .footer-item {
    display: inline-block;
    padding: 0 0.5em;
    border-right: 1px solid #636466;
    line-height: 1.25em;
  }
  .footer-link {
    color: #636466;
    text-decoration: none;
    line-height: inherit;
    line-height: 2em;
  }
  .footer-cr {
    color: #636466;
    line-height: 2em;
  }