$YELLOW: #f3dc13;

@font-face {
  font-family: "Avantt-bold";
  src: url("./assets/Fonts/Avantt/Avantt-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-semiBold";
  src: url("./assets/Fonts/Avantt/Avantt-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-light";
  src: url("./assets/Fonts/Avantt/Avantt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Avantt-regular";
  src: url("./assets/Fonts/Avantt/Avantt-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  font-family: "Avantt";
}

body, html{
  font-family: "Avantt-regular" !important;
  }
// *::-webkit-scrollbar {
//   width: 12px;
// }

// *::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// *::-webkit-scrollbar-thumb {
//   background: #000;
// }

.active {
  border-bottom: 1px solid #fff;
}

.nav-tabs .nav-item a {
  text-decoration: none;
}

.mobileActive {
  text-decoration: none;
  color: #000;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 90px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.app-update-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.349);
}

.mobileInactive {
  text-decoration: none;
  color: #000;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
}

.QuestionCell {
  white-space: pre-wrap !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: #000000 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #000000 !important;
}

.css-p8a3uf-MuiButtonBase-root-MuiButton-root {
  color: #000000 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #000000 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #757575 !important;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #000000 !important;
  color: $YELLOW  !important;
}