.btn-wrapper-top-bar {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  .btn-admin-top {
    span {
      font-weight: bold;
    }
  }
}

.search-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
  .inputStyle {
    width: 14rem;
    margin-right: 10px;
  }
}